// import dayjs from "dayjs";
//atoms
import BlockGrayButton from "@/components/atoms/VButton/BlockGrayButton";
//organisms
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";
import SnackBar from "@/components/organisms/SnackBar/SnackBar";
//Claim
import header from "@/assets/table/claim/claimHeader";

import CreateConfirmDialog from "@/components/organisms/VDialog/CreateConfirmDialog";

import EndClaimTable from "./EndClaimTable";
import numFilterDialog from "@/components/organisms/VDialog/numFilterDialog";
import NewClaimTable from "./NewClaimTable.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    NewClaimTable,
    LoadingSpinner,
    BlockGrayButton,

    SnackBar,
    EndClaimTable,
    numFilterDialog,
    CreateConfirmDialog,
  },
  data() {
    return {
      header,
      approval: {},
      claim: {},
    };
  },

  computed: {
    ...mapGetters({
      claimNewRows: "getClaimNewRows",
      claimList: "getClaimList",
      claimTotal: "getClaimTotal",
    }),
  },
  watch: {},

  methods: {
    onResize() {
      this.isMobile = window.innerHeight - 300;
    },
    confirmDialogOpen(kind) {
      if (kind === "delete" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `삭제할 오더를 선택해주세요.`);
        return;
      }
      if (kind === "end" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `마감할 오더를 선택해주세요.`);
        return;
      }
      if (
        confirm(
          `${this.$store.state.selected.length} 건을 ${
            kind === "delete" ? "삭제" : "케이스 종료"
          } 하시겠습니까?`,
        )
      ) {
        this.$store.dispatch("TASK_SELECT_CLAIM_SAVE", kind);
      }
    },

    checkSelected() {
      return this.$store.state.selected.length ? true : false;
    },
    initFilter() {
      this.$store.commit("setClaimList", this.copyData(this.originalList));
      this.header.forEach((x) => {
        if (x.filterable !== false) x.checked = false;
      });

      this.setFilterList();
    },
  },
  async created() {
    this.$store.dispatch("GET_SELECT_ORDER_LIST");
    this.$store.dispatch("GET_SELECT_CLIENT_LIST");
    this.$store.dispatch("GET_SELECT_USER_LIST");
  },

  mounted() {
    // this.$nextTick(async () => {
    //   window.addEventListener("resize", this.onResize);
    // });
  },
};
