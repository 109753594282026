<template>
  <div class="claimGridTbody NewClaimRow">
    <div
      class="bodyItem text-center"
      v-for="(col, i) in header"
      :key="i"
      :class="{
        claimBorderLeft:
          col.value !== 'order' &&
          col.value !== 'arise_checkbox' &&
          col.value !== 'pass_checkbox',
        claimBorderRight:
          col.value === 'arise_checkbox' || col.value === 'pass_checkbox',
      }"
    >
      <template v-if="col.value === 'order'">
        <v-autocomplete
          v-model="claimNewRows.order"
          dense
          hide-details
          :items="orderList"
          item-text="order"
          return-object
        >
        </v-autocomplete>
      </template>
      <template v-if="col.value === 'liaison'">
        <v-autocomplete
          v-model="claimNewRows.liaison"
          dense
          hide-details
          :items="userList"
          item-text="name"
          return-object
        >
        </v-autocomplete>
      </template>

      <template v-if="col.value === 'arise_month'">
        <div class="pt-0 pb-0">
          <v-menu
            ref="menu_date_register"
            v-model="menu_date_register"
            :close-on-content-click="false"
            :return-value.sync="claimNewRows.arise_month"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="claimNewRows.arise_month"
                v-on="on"
                dense
                :attrs="attrs"
                v-bind="attrs"
                hide-details
                readonly
                class="centered-input"
              ></v-text-field>
            </template>
            <MonthPicker
              v-model="claimNewRows.arise_month"
              @input="
                $refs.menu_date_register[0].save(claimNewRows.arise_month)
              "
            >
            </MonthPicker>
          </v-menu>
        </div>
      </template>

      <template v-if="col.value === 'arise_type'">
        <v-select
          :items="claimType"
          v-model="claimNewRows.arise_type"
          dense
          hide-details
          item-text="label"
          item-value="value"
          class="claimTextField"
        >
        </v-select>
      </template>

      <template v-if="col.value === 'arise_client'">
        <v-autocomplete
          return-object
          v-model="claimNewRows.arise_client"
          dense
          hide-details
          :items="$store.state.useClientList"
          item-text="name"
          :filter="searchClientFilter"
        >
          <template v-slot:item="{ item }">
            {{ item.name }}
            {{ item.bill_client ? ` - ${item.bill_client}` : "" }}
          </template>
        </v-autocomplete>
      </template>
      <template v-if="col.value === 'arise_price'">
        <div class="pt-0 pb-0">
          <v-text-field
            hide-details
            dense
            v-model="claimNewRows.arise_price"
            @input="(e) => (claimNewRows.arise_price = usingComma(e))"
          >
            <template v-slot:prepend-inner>
              <v-select
                :items="$store.state.currency_sign"
                item-text="label"
                item-value="value"
                v-model="claimNewRows.arise_currency_sign"
                hide-details
                flat
                solo
                hide-selected
                class="amountSelect pt-0 pr-0"
                id="claimCurrency"
                ref="salesClaimTable"
              >
                <template v-slot:selection="{ item }">
                  <span>
                    {{ item.label }}
                  </span>
                </template>
              </v-select>
            </template>
            <template v-slot:append>
              <div class="labelInputBox">
                <span>부가세</span>
                <input type="checkbox" v-model="claimNewRows.arise_vat" />
              </div>
            </template>
          </v-text-field>
          <div
            v-if="claimNewRows.arise_currency_sign !== '₩'"
            class="eschamgeCell"
          >
            <v-text-field
              hide-details
              label="환율"
              v-model="claimNewRows.arise_eschamge"
              @input="(e) => (claimNewRows.arise_eschamge = usigComnma(e))"
            >
            </v-text-field>
          </div>
        </div>
      </template>

      <template v-if="col.value === 'arise_passtype'">
        <div class="d-flex">
          <v-select
            :items="passType"
            v-model="claimNewRows.arise_passtype"
            dense
            hide-details
            item-value="value"
            class="claimTextField"
            @change="passTypeChange($event, claimNewRows, 'new')"
          >
            <template v-slot:selection="{ item }">
              <div>
                <v-icon
                  :color="
                    item.value === 1
                      ? 'orange'
                      : item.value === 2
                      ? 'red'
                      : item.value === 3
                      ? 'green'
                      : ''
                  "
                  >mdi-circle-slice-8</v-icon
                >
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div class="d-flex justify-center align-center">
                <v-icon
                  :color="
                    item.value === 1
                      ? 'orange'
                      : item.value === 2
                      ? 'red'
                      : item.value === 3
                      ? 'green'
                      : ''
                  "
                  >mdi-circle-slice-8</v-icon
                >
              </div>
              {{ item.label }}
            </template>
          </v-select>
        </div>
      </template>
      <template v-if="col.value === 'arise_detail'">
        <div class="d-flex">
          <v-text-field hide-details dense v-model="claimNewRows.arise_detail">
          </v-text-field>
        </div>
      </template>
      <template v-if="col.value === 'arise_status'">
        <span> 생성 </span>
      </template>
      <template v-if="col.value === 'ariseNone'">
        <span>
          <v-btn
            color="green"
            small
            @click="saveClaim(claimNewRows, 'create', 'arise')"
            :disabled="$store.state.isExpired"
          >
            <span style="color: white">등록</span>
          </v-btn>
        </span>
      </template>

      <template v-if="col.value === 'pass_month'">
        <div class="pt-0 pb-0">
          <v-menu
            ref="pass_menu_date_register"
            v-model="pass_menu_date_register"
            :close-on-content-click="false"
            :return-value.sync="claimNewRows.pass_month"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="claimNewRows.pass_month"
                v-on="on"
                dense
                :attrs="attrs"
                v-bind="attrs"
                hide-details
                readonly
                class="centered-input"
              ></v-text-field>
            </template>
            <MonthPicker
              v-model="claimNewRows.pass_month"
              @input="
                $refs.pass_menu_date_register[0].save(claimNewRows.pass_month)
              "
            >
            </MonthPicker>
          </v-menu>
        </div>
      </template>

      <template v-if="col.value === 'pass_type'">
        <v-select
          :items="claimType"
          v-model="claimNewRows.pass_type"
          dense
          hide-details
          item-text="label"
          item-value="value"
          class="claimTextField"
        >
        </v-select>
      </template>
      <template v-if="col.value === 'pass_client'">
        <v-autocomplete
          v-model="claimNewRows.pass_client"
          dense
          hide-details
          return-object
          :items="$store.state.useClientList"
          item-text="name"
          :filter="searchClientFilter"
        >
          <template v-slot:item="{ item }">
            {{ item.name }}
            {{ item.bill_client ? ` - ${item.bill_client}` : "" }}
          </template>
        </v-autocomplete>
      </template>
      <template v-if="col.value === 'pass_price'">
        <div class="pt-0 pb-0">
          <v-text-field
            hide-details
            dense
            v-model="claimNewRows.pass_price"
            @input="(e) => (claimNewRows.pass_price = usingComma(e))"
          >
            <template v-slot:prepend-inner>
              <v-select
                :items="$store.state.currency_sign"
                item-text="label"
                item-value="value"
                v-model="claimNewRows.pass_currency_sign"
                hide-details
                flat
                solo
                hide-selected
                class="amountSelect pt-0 pr-0"
                id="claimCurrency"
                ref="salesClaimTable"
              >
                <template v-slot:selection="{ item }">
                  <span>
                    {{ item.label }}
                  </span>
                </template>
              </v-select>
            </template>
            <template v-slot:append>
              <div class="labelInputBox">
                <span>부가세</span>
                <input type="checkbox" v-model="claimNewRows.pass_vat" />
              </div>
            </template>
          </v-text-field>
        </div>
        <div
          v-if="claimNewRows.pass_currency_sign !== '₩'"
          class="eschamgeCell"
        >
          <v-text-field
            hide-details
            label="환율"
            v-model="claimNewRows.pass_eschamge"
            @input="(e) => (item.pass_eschamge = usingComma(e))"
          >
          </v-text-field>
        </div>
      </template>
      <template v-if="col.value === 'pass_kind'">
        <v-select
          :items="claimKind"
          v-model="claimNewRows.pass_kind"
          dense
          hide-details
          item-text="label"
          item-value="value"
        >
        </v-select>
      </template>
      <template v-if="col.value === 'pass_detail'">
        <div class="d-flex">
          <v-text-field hide-details dense v-model="claimNewRows.pass_detail">
          </v-text-field>
        </div>
      </template>
      <template v-if="col.value === 'pass_status'">
        <span> 생성 </span>
      </template>
      <template v-if="col.value === 'passNone'">
        <v-btn
          color="green"
          small
          @click="saveClaim(claimNewRows, 'create', 'pass')"
          :disabled="$store.state.isExpired"
        >
          <span style="color: white">등록</span>
        </v-btn>
      </template>
      <template v-else>
        <div />
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MonthPicker from "@/components/atoms/Vdate/MonthPicker.vue";
export default {
  data() {
    return {
      menu_date_register: false,
      pass_menu_date_register: false,
      claimType: [
        {
          label: "매입",
          value: 2,
        },
        {
          label: "매출",
          value: 3,
        },
        {
          label: "발행 예정",
          value: 1,
        },
        {
          label: "비용",
          value: 4,
        },
      ],
      passType: [
        {
          label: "협의중",
          value: 1,
        },
        {
          label: "불가",
          value: 2,
        },
        {
          label: "전가",
          value: 3,
        },
      ],
      claimKind: [
        {
          label: "발생",
          value: 1,
        },
        {
          label: "전가",
          value: 2,
        },
      ],
    };
  },
  components: {
    MonthPicker,
  },
  props: ["header"],

  computed: {
    ...mapGetters({
      claimNewRows: "getClaimNewRows",
      orderList: "getClaimOrderList",
      userList: "getClaimUserList",
      monthList: "getMonthList",
    }),
  },
  methods: {
    passTypeChange(event, item, type) {
      const changeType =
        event === 1
          ? "협의중"
          : event === 2
          ? "불가"
          : event === 3
          ? "전가"
          : "";
      const arise_Detail = item.arise_detail.split("|");
      if (arise_Detail[0] !== "" && arise_Detail[1] !== "") {
        item.arise_detail =
          "" + (arise_Detail[1] ? arise_Detail[1] : arise_Detail[0]);
      }
      item.arise_detail =
        type === "new" || arise_Detail[1] === ""
          ? `${changeType}|`
          : `${changeType}|${item.arise_detail}`;
    },
    searchClientFilter(item, queryText) {
      const searchTarget = `${item.name} ${
        item.bill_client ? ` - ${item.bill_client}` : ""
      }  `;

      return (
        searchTarget
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        searchTarget
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    claimRowCheck(item, target) {
      if (!item.order) {
        this.$store.commit("setSnackBar", `오더를 선택해주세요.`);
        return false;
      }
      if (!item.liaison) {
        this.$store.commit("setSnackBar", `담당자를 선택해주세요.`);
        return false;
      }
      if (target === "arise") {
        if (!item.arise_month) {
          this.$store.commit("setSnackBar", `월을 선택해주세요.`);
          return false;
        }
        if (!item.arise_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!item.arise_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      } else {
        if (!item.pass_month) {
          this.$store.commit("setSnackBar", `월을 선택해주세요.`);
          return false;
        }

        if (!item.pass_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!item.pass_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      }

      return true;
    },
    saveClaim(item, type, target) {
      if (!this.claimRowCheck(item, target)) return;
      this.$store.commit("setClaimForm", item);

      const form = {
        type: type,
        target: target,
        saveForm: { ...item },
      };

      this.$store.dispatch("TASK_CLAIM_SAVE", form);
    },
  },
};
</script>
