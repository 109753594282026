import header from "@/assets/table/claim/claimHeader";

import { mapGetters } from "vuex";
import dayjs from "dayjs";
// import ClaimMemo from "@/components/atoms/VTooltip/ClaimMemo";
import OrderStatus from "@/assets/data/status/OrderStatus";
// import MonthPicker from "@/components/atoms/Vdate/MonthPicker.vue";
import ListContextMenu from "@/components/atoms/ContextMenu/ListContextMenu.vue";
import filterNormal from "@/assets/image/svg/filter.svg";
import filterActive from "@/assets/image/svg/filter_active.svg";
import CreateTable from "./CreateForm";
import modifyForm from "./ModfiyFom";
import bodyForm from "./BodyForm";
import { claimFilters, targetClaimFind } from "@/api/claim.js";
import commitName from "@/components/pages/common/ApiActionName.js";
import filterStatus from "@/utils/claimStatus";

export default {
  components: {
    ListContextMenu,
    filterNormal,
    filterActive,
    CreateTable,
    modifyForm,
    bodyForm,
  },
  props: ["type"],
  computed: {
    ...mapGetters({
      pageList: "getClaimList",
      pageParams: "getClaimParam",
      totalCount: "getClaimTotalCount",
      sort: "getClaimSort",

      modifyOrderList: "getConfirmOrderList",
      claimForm: "getClaimForm",
      monthList: "getMonthList",

      ariseAllChecked: "getAriseAllChecked",
      passAllChecked: "getPassAllChecked",
    }),
    searchData() {
      return this.setSearchData("search", this.names.searchCommitName);
    },
    totalData() {
      return this.setSearchData("search", this.names.totalCommitName);
    },
    infiniteScrollData() {
      return this.setSearchData("infinite", this.names.infiniteCommitName);
    },
    isPluseParamBtn() {
      const paramIndex = this.pageParams.findIndex(
        (param) => param.field === this.filterField,
      );
      return !!this.isKeyword && -1 < paramIndex;
    },
    isApplyBtn() {
      let isApply = this.isKeyword
        ? this.isPluseParam
          ? !this.isPluseParam
          : !this.keywordFilters.filter((x) => x.checked).length
        : !this.filters.filter((x) => x.checked).length;
      return isApply;
    },
  },
  data() {
    return {
      header,
      observer: null,
      names: commitName["claim"],

      filterField: "", // 현재 선택한 filter field
      isFilter: false,
      allChecked: false, // 모두 선택 checkbox
      keywordAllChecked: false, // keywod 검색 filter 모두 선택 check box
      filters: [], // 현재 filter 데이터
      params: [], // checked 가 true 데이터
      infinitieSearchField: "", //무한 스크롤 field
      // sort: 1, // 정렬 선택 1: 오림차순, -1 :내림 차순
      isKeyword: false, // filter keyword 입력 여부
      findKeyword: "", // filter 검색을 위한 keyword
      keywordFilters: [], // keyword 가 포함된 filter
      isPluseParam: false,

      // isEndCase: "false",

      selected: [],
      claimType: [
        {
          label: "매입",
          value: 2,
        },
        {
          label: "매출",
          value: 3,
        },
        {
          label: "발행 예정",
          value: 1,
        },
        {
          label: "비용",
          value: 4,
        },
      ],
      passType: [
        {
          label: "협의중",
          value: 1,
        },
        {
          label: "불가",
          value: 2,
        },
        {
          label: "전가",
          value: 3,
        },
      ],
      claimKind: [
        {
          label: "발생",
          value: 1,
        },
        {
          label: "전가",
          value: 2,
        },
      ],
      setFilter: {},
      firSetFilter: {
        filter: [],
        name: "",
      },
      findFilterList: [],
      searchValue: "",
      headerList: {},
      newRows: [],
      dayjs,
      ariseOnRow: {
        index: "",
        id: "",
      },
      passOnRow: {
        index: "",
      },
      passNewOnRow: {
        index: "",
      },
      ariseNewOnRow: {
        index: "",
        id: "",
      },
      isEdit: false,
      onRow: {},
      ariseModify: {},
      passModify: {},
      processForm: {},
      scrollSync1: null,
      scrollSync2: null,
      lengthStat: false,
      ariseStat: true,
      endStat: true,

      process_menu_date_register: [],
      process_pass_menu_date_register: [],
      modify_menu_date_register: [],
      modify_pass_menu_date_register: [],
      bodyHeight: 0,
      ariseHeader: [
        "arise_checkbox",
        "arise_month",
        "arise_type",
        "arise_client",
        "arise_price",
        "arise_detail",
        "arise_status",
        "arise_modify",
        "arise_passtype",
        "ariseNone",
      ],
      passHeader: [
        "pass_checkbox",
        "pass_month",
        "pass_type",
        "pass_client",
        "pass_price",
        "pass_detail",
        "pass_status",
        "pass_modify",
        "passNone",
      ],
      keydownListener: null,
    };
  },
  methods: {
    /**
     * api 호출
     */
    // 검색 할 값 생성
    setSearchData(type, name) {
      if (!this.pageParams.length) this.infinitieSearchField = "";
      const data = {
        param: {
          multi: JSON.stringify(this.pageParams),
          field:
            type === "infinite"
              ? this.infinitieSearchField
              : this.setFilterField(),
          sort: this.sort,
          isEndCase: this.names.isEndCase,
        },
        commitName: name,
        isInfinite: type !== "infinite",
      };
      if (type === "infinite") data.param.skip = this.pageList.length;

      return data;
    },
    // 검색 api 호출
    search() {
      this.callTotalCountAPI();
      this.callSearchAPI();
    },

    // 검색시 sort field 생성
    setFilterField() {
      let field = "";
      if (this.filterField) {
        field = this.filterField;
      }
      if (this.pageParams.length && !this.filterField) {
        field = this.pageParams[this.pageParams.length - 1].field;
      }
      return field;
    },

    // filter api 호출시 현재 필드 값을 뺴고 multi 생성
    setFilterParam() {
      let params = [];
      const filterIndex = this.pageParams.findIndex(
        (x) => x.field === this.filterField,
      );

      if (-1 < filterIndex) {
        for (let i = 0; i < filterIndex; i++) {
          params.push(this.pageParams[i]);
        }
      } else params = this.pageParams;

      return JSON.stringify(params);
    },

    // 내부 필터 데이터 호출 api
    async setFilters() {
      const head = header.find((haed) => haed.value === this.filterField);
      try {
        const result = await claimFilters({
          company: this.$store.state.auth_company,
          params: {
            field: this.filterField,
            multi: this.setFilterParam(),
            isEndCase: this.names.isEndCase,
            sort: head.sort,
          },
        });

        if (result.status !== 200) throw `error: ${result.status}`;

        const params = this.copyData(
          this.pageParams.find((x) => x.field === this.filterField),
        );

        if (!params) {
          this.filters = result.data.length ? result.data : [];
          this.setParams();
        } else {
          this.params = params.params;
          this.filters = result.data.map((x) => {
            const param = this.params.find((name) => name === x.name);
            return {
              name: x.name,
              checked: param !== undefined,
            };
          });
          this.allChecked = this.params.length === this.filters.length;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 오더 data 검색 api
    async callSearchAPI() {
      this.$store.dispatch(this.names.searchApiName, this.searchData);
      this.infinitieSearchField = this.setFilterField();
    },

    //무한스크롤 API
    async infiniteScroll() {
      this.$store.dispatch(this.names.searchApiName, this.infiniteScrollData);
    },

    //검색된 오더 total count api
    async callTotalCountAPI() {
      this.$store.dispatch(this.names.totalApiName, this.totalData);
      // this.ariseAllChecked = false;
      // this.passAllChecked = false;
    },

    //head 에 값에 따로 sort 값 전달
    headSort(field) {
      const head = header.find((haed) => haed.value === field);
      return head && head.sort ? head.sort : 1;
    },

    /**
     * 필터 이벤트
     */
    // 필터 비콘 클릭 이벤트
    filterBeaconClick(head) {
      this.filterField !== head ? this.filterSet(head) : this.initFilter();
      this.findKeyword = "";
      this.params = [];
    },

    //필터 초기화 기능
    initFilter() {
      this.filterField = "";
      this.findKeyword = "";
      this.isFilter = false;
      this.allChecked = false;
      this.isKeyword = false;
      this.filters = [];
      this.keywordFilters = [];
    },

    // 필터 set 기능
    filterSet(head) {
      this.filterField = head;
      this.isFilter = true;
      this.filters = [];
      this.setFilters();
    },

    // 필터 활성화시 최초 params set
    setParams() {
      this.params = this.filters.filter((x) => x.checked).map((x) => x.name);
      this.allChecked = this.params.length === this.filters.length;
    },

    allChekedClick() {
      this.allChecked = !this.allChecked;
      this.filters = this.filters.map((x) => {
        x.checked = this.allChecked;
        return x;
      });
      this.params = this.allChecked
        ? this.filters.filter((x) => x.checked).map((x) => x.name)
        : [];
    },

    // checkbox 클릭시 이벤트
    checkBoxClick(i) {
      this.filterChecked(i);
      this.modifyParams(i);
      this.allChecked = this.params.length === this.filters.length;
    },

    // filter checked 변경
    filterChecked(i) {
      const filters = this.copyData(this.filters);
      filters[i].checked = !filters[i].checked;
      this.filters = filters;
    },

    // 현재 활성화된 filter 검색 params set
    modifyParams(i) {
      const filter = this.filters[i];
      if (filter.checked) {
        this.params.push(filter.name);
        this.params = [...new Set(this.params)];
      } else {
        const index = this.params.findIndex((x) => x === filter.name);
        this.params.splice(index, 1);
      }
    },

    // 필터 적용
    applyBtnClick() {
      this.$store.commit(
        this.names.sortCommitName,
        this.headSort(this.filterField),
      );
      // this.sort = this.headSort(this.filterField);
      // this.sort = 1;
      this.commitParams();
      this.search();
      // this.modifyBeacon(true);
      this.initHeader();
      this.initFilter();
    },

    // 오름, 내림 차순
    sortBtnClick(sort) {
      // this.sort = sort;
      this.$store.commit(this.names.sortCommitName, sort);
      this.search();
      this.initFilter();
    },

    // beacon checked 변경
    modifyBeacon(checked) {
      const index = header.findIndex((head) => head.value === this.filterField);
      header[index].checked = checked;
    },

    // 검색 버튼 클릭시 멀티 params 값 변경
    commitParams() {
      const pageParams = this.copyData(this.pageParams);
      const paramIndex = this.pageParams.findIndex(
        (param) => param.field === this.filterField,
      );

      const params = {
        field: this.filterField,
        params: this.params,
      };
      if (paramIndex < 0) pageParams.push(params);
      else pageParams[paramIndex] = params;

      this.$store.commit("setClaimParam", pageParams);
    },

    monthFilter(month, type) {
      const list = this.filters.filter(
        (date) => this.monthParse(date.name, type) === month,
      );

      if (list.length) {
        this.params = list.map((date) => date.name);
        this.applyBtnClick();
      } else this.initParams();
    },

    monthParse(date, type) {
      let parseDate = date ? date : "";

      if (type === "mmdd") parseDate = parseDate.substr(0, 2);

      if (type === "yymm") parseDate = parseDate.substr(3, 5);

      if (type === "yyyymm") parseDate = parseDate.substring(5, 7);

      if (type === "m" && +date < 10) parseDate = `0${parseDate}`;

      return parseDate;
    },

    // 필터 해제
    initParams() {
      this.params = [];
      const orderParams = this.copyData(this.pageParams);
      const index = orderParams.findIndex(
        (param) => param.field === this.filterField,
      );
      orderParams.splice(index, 1);
      this.$store.commit(this.names.paramName, orderParams);
      this.setFilters();
      this.initFilter();
      this.$store.commit(
        this.names.sortCommitName,
        this.headSort(this.setFilterField()),
      );
      this.search();
      this.initHeader();
    },

    /**
     * 페이지 출력 함수 모음
     */

    // 숫자 필터 사용시 검색 함수
    numFiltering(field, pageParams) {
      this.filterField = field;
      this.$store.commit(this.names.paramName, pageParams);
      this.search();
      this.initHeader();
      this.initFilter();
    },

    // 숫자 필터 다이어로그 open
    numDialogOpen(type) {
      this.$store.dispatch("NUM_DIALOG_OPEN", {
        field: this.filterField,
        filters: this.copyData(this.filters),
        params: this.copyData(this.pageParams),
        type: type,
        numFiltering: this.numFiltering,
      });
    },

    /**
     * 필터 검색
     */

    // 입력된 keyword 를 filter List 검색
    inputKeyowrd() {
      this.isPluseParam = false;
      this.isKeyword = !!this.findKeyword;

      const findKeyword =
        this.filterField !== "arise_status" &&
        this.filterField !== "pass_status"
          ? this.findKeyword.toLowerCase()
          : filterStatus[this.findKeyword];

      this.keywordFilters = this.copyData(
        this.filters.filter((x) =>
          findKeyword === null
            ? !x.name
            : String(x.name).toLowerCase().includes(findKeyword),
        ),
      ).map((x) => {
        x.checked = true;
        return x;
      });

      this.keywordAllChecked = this.keywordFilters.length
        ? !this.keywordFilters.find((x) => !x.checked)
        : false;
    },

    // 입력된 keyword filter checkbox 클릭
    keywordCheckBoxClick(i) {
      this.keywordFilters[i].checked = !this.keywordFilters[i].checked;
      const index = this.filters.findIndex(
        (x) => x.name === this.keywordFilters[i].name,
      );
      this.keywordAllChecked = !this.keywordFilters.filter((x) => !x.checked)
        .length;

      this.checkBoxClick(index);
    },

    // 입력된 keyword filter 전체버튼 클릭
    keywordAllcheckedClick() {
      const params = this.params;
      this.keywordAllChecked = !this.keywordAllChecked;
      this.keywordFilters = this.keywordFilters.map((x) => {
        x.checked = this.keywordAllChecked;
        return x;
      });
      this.keywordFilters.forEach((filter) => {
        if (this.keywordAllChecked) {
          params.push(filter.name);
        } else {
          const index = this.params.findIndex((x) => x === filter.name);
          params.splice(index, 1);
        }
      });
      this.params = [...new Set(params)];
    },

    keywordApplyBtnClick() {
      const keywordParam = this.keywordFilters
        .filter((x) => x.checked)
        .map((x) => x.name);

      if (!this.isPluseParam)
        this.params = keywordParam.length ? keywordParam : this.params;

      if (this.isPluseParam) {
        const originParam = this.filters
          .filter((x) => x.checked)
          .map((x) => x.name);

        this.params = [...new Set([...originParam, ...keywordParam])];
      }

      this.applyBtnClick();
    },

    async checkScroll() {
      const scroll = this.$refs.endPoint;
      this.observer = new IntersectionObserver(
        async ([entry]) => {
          if (entry.isIntersecting && this.pageList.length < this.totalCount) {
            this.infiniteScroll();
          }
        },
        {
          threshold: 0.5,
        },
      );
      this.observer.observe(scroll);
    },

    initHeader() {
      header.map((head) => {
        head.checked = !!this.pageParams.find(
          (param) => param.field === head.value,
        );
        return head;
      });
    },

    statusParse(item) {
      return OrderStatus[item];
    },

    async pageMove() {
      this.pageParams.splice(0, 1);
      this.$store.commit("setClaimParam", this.pageParams);
      await this.callTotalCountAPI();

      if (this.totalCount) this.callSearchAPI();
      else {
        this.$store.commit("setClaimParam", []);
        this.search();
      }

      this.initHeader();
    },

    allCheckItem(type) {
      if (type === "arise_checkbox") {
        this.checkBoxCommit("arise", !this.ariseAllChecked);
        this.pageList.forEach((x) => {
          if (x.arise_id) x.arise_checked = this.ariseAllChecked;

          if (
            this.ariseAllChecked &&
            x.arise_checked &&
            !this.$store.state.ariseSelected.includes(x)
          )
            x.saveTarget = "arise";
          // this.$store.state.ariseSelected.push(x);
        });
        // if (!this.ariseAllChecked) this.$store.state.ariseSelected = [];
      } else {
        this.checkBoxCommit("pass", !this.passAllChecked);
        this.pageList.forEach((x) => {
          if (x.pass_id) x.pass_checked = this.passAllChecked;

          if (
            this.passAllChecked &&
            x.pass_checked &&
            !this.$store.state.passSelected.includes(x)
          )
            x.saveTarget = "pass";
          this.$store.state.passSelected.push(x);
        });
        // if (!this.passAllChecked) this.$store.state.passSelected = [];
      }
      this.setCheckBoxLength();
    },

    checkBoxCommit(type, isBox) {
      this.$store.commit(
        type === "arise"
          ? this.names.ariseCheckboxCommitName
          : this.names.passCheckboxCommitName,
        isBox,
      );
    },

    setCheckBoxLength() {
      this.$store.dispatch("SET_CHECKBOXLENGTH", this.pageList);
    },

    // ----------------
    async clickRow(i, item, type) {
      this.ariseNewOnRow.index = null;
      this.passNewOnRow.index = null;
      const saveClaim = await targetClaimFind(
        this.$store.state.auth_company,
        item._id,
      );
      const formClaim = saveClaim && saveClaim.data[0];

      if (type === "arise") {
        this.ariseOnRow.index = i;
        this.ariseOnRow.id = item.order;
        this.ariseModify = JSON.parse(JSON.stringify(formClaim));
        this.ariseModify.index = i;

        this.ariseModify.arise_type =
          item.arise_type === "매입"
            ? 2
            : item.arise_type === "매출"
            ? 3
            : item.arise_type === "비용"
            ? 4
            : 1;
        this.ariseModify.arise_client = this.$store.state.useClientList.find(
          (x) => x.name === item.arise_client,
        );
        this.passOnRow = {
          index: "",
          id: "",
        };

        this.passModify = {};
      } else {
        this.passOnRow.index = i;
        this.passOnRow.id = item.order;
        this.passModify = JSON.parse(JSON.stringify(formClaim));

        this.passModify.index = i;
        this.passModify.pass_type =
          item.pass_type === "매입"
            ? 2
            : item.pass_type === "매출"
            ? 3
            : item.pass_type === "비용"
            ? 4
            : 1;
        this.passModify.pass_client = this.$store.state.useClientList.find(
          (x) => x.name === item.pass_client,
        );
        this.ariseOnRow = {
          index: "",
          id: "",
        };
        this.ariseModify = {};
      }
    },
    clearRow(type) {
      if (type === "arise") {
        this.ariseOnRow = {
          index: "",
          id: "",
        };
        this.ariseModify = {};
      } else {
        this.passOnRow = {
          index: "",
          id: "",
        };
        this.passModify = {};
      }
    },

    addRow() {
      this.$store.commit("setClaimNewRows");
      this.$store.state.claimEdit = true;
      this.claimNewRows.arise_month = dayjs().format("YYYY-MM");
      this.claimNewRows.arise_currency_sign = "₩";
      this.claimNewRows.arise_detail = "협의중";
      this.claimNewRows.arise_passtype = 1;
      this.claimNewRows.pass_month = dayjs().format("YYYY-MM");
      this.claimNewRows.pass_currency_sign = "₩";
      this.claimNewRows.pass_detail = "";
      this.claimNewRows.arise_vat = true;
      this.claimNewRows.pass_vat = true;
    },
    deleteRow(index) {
      if (!this.$store.state.claimEdit) return;
      this.$store.state.claimEdit = !this.$store.state.claimEdit;
    },

    processClaimRowCheck(item, target) {
      if (!item.order) {
        this.$store.commit("setSnackBar", `오더를 선택해주세요.`);
        return false;
      }
      if (!item.liaison) {
        this.$store.commit("setSnackBar", `담당자를 선택해주세요.`);
        return false;
      }
      if (target === "arise") {
        if (!item.arise_month) {
          this.$store.commit("setSnackBar", `월을 선택해주세요.`);
          return false;
        }
        if (!item.arise_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!item.arise_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      } else {
        if (!item.pass_month) {
          this.$store.commit("setSnackBar", `월을 선택해주세요.`);
          return false;
        }
        if (!item.pass_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!item.pass_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      }
      return true;
    },
    claimRowCheck(item, target) {
      if (!item.order) {
        this.$store.commit("setSnackBar", `오더를 선택해주세요.`);
        return false;
      }
      if (!item.liaison) {
        this.$store.commit("setSnackBar", `담당자를 선택해주세요.`);
        return false;
      }
      if (target === "arise") {
        if (!item.arise_month) {
          this.$store.commit("setSnackBar", `월을 선택해주세요.`);
          return false;
        }
        if (!item.arise_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!item.arise_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      } else {
        if (!item.pass_month) {
          this.$store.commit("setSnackBar", `월을 선택해주세요.`);
          return false;
        }

        if (!item.pass_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!item.pass_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      }

      return true;
    },
    modifyClaimRowCheck(target) {
      if (target === "arise") {
        if (!this.ariseModify.order) {
          this.$store.commit("setSnackBar", `오더를 선택해주세요.`);
          return false;
        }
        if (!this.ariseModify.liaison) {
          this.$store.commit("setSnackBar", `담당자를 선택해주세요.`);
          return false;
        }
        if (!this.ariseModify.arise_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!this.ariseModify.arise_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      } else {
        if (!this.passModify.order) {
          this.$store.commit("setSnackBar", `오더를 선택해주세요.`);
          return false;
        }
        if (!this.passModify.liaison) {
          this.$store.commit("setSnackBar", `담당자를 선택해주세요.`);
          return false;
        }
        if (!this.passModify.pass_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!this.passModify.pass_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      }

      return true;
    },
    saveClaim(item, type, target) {
      if (
        (type === "create" && !this.claimRowCheck(item, target)) ||
        (type === "process" && !this.processClaimRowCheck(item, target)) ||
        (type === "modify" && !this.modifyClaimRowCheck(target))
      )
        return;

      this.$store.commit("setClaimForm", item);

      const form = {
        type: type,
        target: target,
        saveForm: { ...item },
      };

      this.$store.dispatch("TASK_CLAIM_SAVE", form);

      if (type === "modify" || type === "userModify") this.clearRow(target);
      if (type === "process") this.clearNewRow(target);
    },
    setCheckSelected() {
      const ariseSelected = [];
      const passSelected = [];

      this.pageList.forEach((x) => {
        if (x.arise_checked) ariseSelected.push(x);
        if (x.pass_checked) passSelected.push(x);
      });
      this.$store.commit("setAriseSelected", ariseSelected);
      this.$store.commit("setPassSelected", passSelected);
    },
    selectSave(type) {
      this.setCheckSelected();
      const messageType = {
        create: this.$store.state.auth_grade >= 2 ? "등록" : "등록 요청",
        reject: "반려",
        carry: "이월",
        delete: this.$store.state.auth_grade >= 2 ? "삭제" : "삭제 요청",
        end: this.$store.state.auth_grade >= 2 ? "종료" : "종료 요청",
        userModify: "승인요청 수정",
      };
      const endSnackMessege = {
        lengthStat: "종료할 오더를 선택해주세요",
        ariseStat: "전가 미완료 된 오더만 선택해주세요.",
        endStat: "마감 된 오더만 선택해주세요.",
      };
      if (type === "delete" && !this.checkSelected()) {
        if (!this.lengthStat) {
          this.$store.commit("setSnackBar", endSnackMessege["lengthStat"]);
          return;
        }
        if (!this.endStat) {
          this.$store.commit(
            "setSnackBar",
            "마감되지 않은 오더만 선택해주세요",
          );
          return;
        }
        return;
      }
      if (type === "end" && !this.endCheckSelected()) {
        if (!this.lengthStat) {
          this.$store.commit("setSnackBar", endSnackMessege["lengthStat"]);
          return;
        }

        if (!this.ariseStat) {
          this.$store.commit("setSnackBar", endSnackMessege["ariseStat"]);
          return;
        }

        if (!this.endStat) {
          this.$store.commit("setSnackBar", endSnackMessege["endStat"]);
          return;
        }
      }
      if (type === "carry" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `이월할 오더를 선택해주세요.`);
        return;
      }
      if (
        confirm(
          `${
            this.$store.state.ariseSelected.length +
            this.$store.state.passSelected.length
          } 건을 ${messageType[type]} 하시겠습니까?`,
        )
      ) {
        const saveTarget = {
          type: type,
        };
        this.$store.state.selected = [
          ...this.$store.state.ariseSelected,
          ...this.$store.state.passSelected,
        ];
        this.$store.dispatch("TASK_SELECT_CLAIM_SAVE", saveTarget);
      }
    },
    inputMonth(item, ref) {
      this.$nextTick(() => {
        ref[0].save(item);
      });
    },
    menuOpen(event, item, target) {
      if (!item[`${target}_id`]) this.$store.commit("setApprvoalHistory");
      if (item[`${target}_id`]) {
        this.$store.dispatch("CONTEXT_MENU_DATA_PARSE", {
          item: item,
          target: target,
          name: "claim",
          id: item[`${target}_id`],
        });
      }
      this.$refs.menu.$children[0].open(event);
    },

    clickNewRow(i, item, type) {
      if (type === "arise") {
        this.ariseNewOnRow.index = i;
        this.passOnRow = {
          index: "",
          id: "",
        };
      } else {
        this.passNewOnRow.index = i;
        this.ariseOnRow = {
          index: "",
          id: "",
        };
      }

      this.processForm = JSON.parse(JSON.stringify(item));

      if (this.processForm.liaison && this.processForm.liaison !== "미선택")
        this.processForm.liaison = this.$store.state.userList.find(
          (data) => data.name === this.processForm.liaison,
        );
    },
    clearNewRow(type) {
      if (type === "arise") {
        this.ariseNewOnRow = {
          index: "",
          id: "",
        };
      } else {
        this.passNewOnRow = {
          index: "",
          id: "",
        };
      }
    },
    passTypeChange(event, item, type) {
      const changeType =
        event === 1
          ? "협의중"
          : event === 2
          ? "불가"
          : event === 3
          ? "전가"
          : "";
      const arise_Detail = item.arise_detail.split("|");
      if (arise_Detail[0] !== "" && arise_Detail[1] !== "") {
        item.arise_detail =
          "" + (arise_Detail[1] ? arise_Detail[1] : arise_Detail[0]);
      }
      item.arise_detail =
        type === "new" || arise_Detail[1] === ""
          ? `${changeType}|`
          : `${changeType}|${item.arise_detail}`;
    },

    modifyDisableClassify(item, type) {
      const notdisabledSatus =
        this.$store.state.auth_grade >= 3
          ? ["00", "30", "40"]
          : ["00", "20", "30", "40"];
      const isdisabledStatus = notdisabledSatus.includes(
        item[`${type}_status`],
      );

      return !isdisabledStatus;
    },
  },

  async created() {
    this.$store.commit("setAriseSelected");
    this.$store.commit("setPassSelected");

    if (this.pageParams.length && this.pageParams[0].field === "pageMove")
      this.pageMove();
    else {
      this.search();
    }
  },
  mounted() {
    this.keydownListener = (e) => {
      if (e.keyCode == 27 && this.isFilter) {
        this.initFilter();
      }
    };
    document.addEventListener("keydown", this.keydownListener);

    this.checkScroll();
  },

  sockets: {
    async claim(data) {
      if (this.$store.state.auth_company === data.company)
        this.$store.dispatch("PARSE_ROUTE_API_DATA_CALL");
    },
  },
  beforeDestroy() {
    // this.observer.disconnect();
    document.removeEventListener("keydown", this.keydownListener);
    this.keydownEventListener = null;
    this.observer.unobserve(this.$refs.endPoint);
    this.observer.disconnect();
  },
};
