<template>
  <div class="modifyForm">
    <template v-if="header.value === 'order'">
      <v-autocomplete
        v-model="form.order"
        dense
        hide-details
        :items="modifyOrderList"
        item-text="order"
        return-object
        :disabled="form.arise_status === '00' || form.pass_status === '00'"
      >
      </v-autocomplete>
    </template>
    <template v-if="header.value === 'liaison'">
      <v-autocomplete
        v-model="form.liaison"
        dense
        hide-details
        :items="userList"
        item-text="name"
        return-object
        :disabled="form[`${type}_status`] === '00'"
      >
      </v-autocomplete>
    </template>
    <template v-if="header.value === `${type}_month`">
      <v-menu
        :ref="`modify_menu_date_register${index}`"
        v-model="modify_menu_date_register[index]"
        :close-on-content-click="false"
        :return-value.sync="form[`${type}_month`]"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        class="monthPicker"
        :disabled="form[`${type}_status`] === '00'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form[`${type}_month`]"
            v-on="on"
            dense
            :attrs="attrs"
            v-bind="attrs"
            hide-details
            readonly
            class="centered-input"
            :disabled="form[`${type}_status`] === '00'"
          ></v-text-field>
        </template>
        <MonthPicker
          v-model="form[`${type}_month`]"
          @input="
            inputMonth(
              form[`${type}_month`],
              $refs[`modify_menu_date_register${index}`],
            )
          "
        >
        </MonthPicker>
      </v-menu>
    </template>
    <template v-if="header.value === `${type}_type`">
      <div style="width: 100%">
        <v-select
          :items="claimType"
          v-model="form[`${type}_type`]"
          dense
          hide-details
          item-text="label"
          item-value="value"
          :disabled="form[`${type}_status`] === '00'"
        >
        </v-select>
      </div>
    </template>
    <template v-if="header.value === `${type}_client`">
      <v-autocomplete
        v-model="form[`${type}_client`]"
        dense
        hide-details
        :items="$store.state.useClientList"
        class="vatSelect"
        item-text="name"
        item-value="_id"
        style="font-size: small"
        :filter="searchClientFilter"
        :disabled="form[`${type}_status`] === '00'"
      >
        <template v-slot:item="{ item }">
          {{ item.name }}
          {{ item.bill_client ? ` - ${item.bill_client}` : "" }}
        </template>
      </v-autocomplete>
    </template>
    <template v-if="header.value === `${type}_price`">
      <div>
        <span class="pt-0 pb-0">
          <v-text-field
            hide-details
            dense
            v-model="form[`${type}_price`]"
            @input="(e) => (form[`${type}_price`] = usingComma(e))"
            :disabled="form[`${type}_status`] === '00'"
          >
            <template v-slot:prepend-inner>
              <v-select
                :items="$store.state.currency_sign"
                item-text="label"
                item-value="value"
                v-model="form[`${type}_currency_sign`]"
                hide-details
                flat
                solo
                hide-selected
                class="amountSelect pt-0 pr-0"
                id="claimCurrency"
                ref="salesClaimTable"
                :disabled="form[`${type}_status`] === '00'"
                :background-color="
                  item.arise_id &&
                  ariseHeader.includes(header.value) &&
                  (item.arise_passtype === 1 || item.order === '미선택')
                    ? '#fff7ea'
                    : (ariseHeader.includes(header.value) && !item.arise_id) ||
                      (passHeader.includes(header.value) && !item.pass_id)
                    ? '#ebf3fa'
                    : ''
                "
              >
                <template v-slot:selection="{ item }">
                  <span>
                    {{ item.label }}
                  </span>
                </template>
              </v-select>
            </template>
            <template v-slot:append>
              <div class="labelInputBox">
                <span>부가세</span>
                <input
                  type="checkbox"
                  v-model="form[`${type}_vat`]"
                  :disabled="form[`${type}_status`] === '00'"
                />
              </div>
            </template>
          </v-text-field>
        </span>
        <span v-if="form[`${type}_currency_sign`] !== '₩'" class="eschamgeCell">
          <v-text-field
            hide-details
            label="환율"
            v-model="form[`${type}_eschamge`]"
            @input="(e) => (form[`${type}_eschamge`] = usingComma(e))"
            :disabled="form[`${type}_status`] === '00'"
          >
          </v-text-field>
        </span>
      </div>
    </template>
    <template v-if="header.value === 'arise_passtype' && type === 'arise'">
      <v-select
        :items="passType"
        v-model="form['arise_passtype']"
        dense
        hide-details
        item-value="value"
        class="claimTextField"
        @change="passTypeChange($event, form, 'new')"
      >
        <template v-slot:selection="{ item }">
          <div>
            <v-icon
              :color="
                item.value === 1
                  ? 'orange'
                  : item.value === 2
                  ? 'red'
                  : item.value === 3
                  ? 'green'
                  : ''
              "
              >mdi-circle-slice-8</v-icon
            >
          </div>
        </template>
        <template v-slot:item="{ item }">
          <div class="d-flex justify-center align-center">
            <v-icon
              :color="
                item.value === 1
                  ? 'orange'
                  : item.value === 2
                  ? 'red'
                  : item.value === 3
                  ? 'green'
                  : ''
              "
              >mdi-circle-slice-8</v-icon
            >
          </div>
          {{ item.label }}
        </template>
      </v-select>
    </template>
    <template v-else-if="header.value === `${type}_status`">
      <div style="width: 100%">
        {{ statusParse(form[`${type}_status`]) }}
      </div>
    </template>
    <template v-if="header.value === `${type}_detail`">
      <v-text-field
        class="claimTextField pt-0 mt-0"
        v-model="form[`${type}_detail`]"
        hide-details
      />
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MonthPicker from "@/components/atoms/Vdate/MonthPicker.vue";
import OrderStatus from "@/assets/data/status/OrderStatus";
export default {
  components: {
    MonthPicker,
  },
  props: ["header", "form", "type", "index", "ariseRow", "passRow", "item"],
  data() {
    return {
      modify_menu_date_register: [],
      modify_pass_menu_date_register: [],
      claimType: [
        {
          label: "매입",
          value: 2,
        },
        {
          label: "매출",
          value: 3,
        },
        {
          label: "발행 예정",
          value: 1,
        },
        {
          label: "비용",
          value: 4,
        },
      ],
      passType: [
        {
          label: "협의중",
          value: 1,
        },
        {
          label: "불가",
          value: 2,
        },
        {
          label: "전가",
          value: 3,
        },
      ],
      ariseHeader: [
        "arise_checkbox",
        "arise_month",
        "arise_type",
        "arise_client",
        "arise_price",
        "arise_detail",
        "arise_status",
        "arise_modify",
        "arise_passtype",
        "ariseNone",
      ],
      passHeader: [
        "pass_checkbox",
        "pass_month",
        "pass_type",
        "pass_client",
        "pass_price",
        "pass_detail",
        "pass_status",
        "pass_modify",
        "passNone",
      ],
    };
  },
  computed: {
    ...mapGetters({
      modifyOrderList: "getClaimOrderList",
      userList: "getClaimUserList",
    }),
  },
  methods: {
    inputMonth(item, ref) {
      this.$nextTick(() => {
        ref.save(item);
      });
    },
    checkItem(item, i, type) {
      if (type === "arise") {
        item.arise_checked = !item.arise_checked;
        item.arise_index = i;
        if (item.arise_checked) {
          item.saveTarget = "arise";

          if (this.$store.state.ariseSelected.length === this.pageList.length)
            this.ariseAllChecked = true;
        } else {
          this.ariseAllChecked = false;
        }
      } else {
        item.pass_checked = !item.pass_checked;
        item.pass_index = i;
        if (item.pass_checked) {
          item.saveTarget = "pass";

          if (this.$store.state.passSelected.length === this.pageList.length)
            this.passAllChecked = true;
        } else {
          this.passAllChecked = false;
        }
      }
    },
    searchClientFilter(item, queryText) {
      const searchTarget = `${item.name} ${
        item.bill_client ? ` - ${item.bill_client}` : ""
      }  `;

      return (
        searchTarget
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        searchTarget
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    passTypeChange(event, item, type) {
      const changeType =
        event === 1
          ? "협의중"
          : event === 2
          ? "불가"
          : event === 3
          ? "전가"
          : "";
      const arise_Detail = item.arise_detail.split("|");
      if (arise_Detail[0] !== "" && arise_Detail[1] !== "") {
        item.arise_detail =
          "" + (arise_Detail[1] ? arise_Detail[1] : arise_Detail[0]);
      }
      item.arise_detail =
        type === "new" || arise_Detail[1] === ""
          ? `${changeType}|`
          : `${changeType}|${item.arise_detail}`;
    },
    statusParse(item) {
      return OrderStatus[item];
    },
  },
};
</script>
