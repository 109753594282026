<template>
  <div>
    <!-- 발생 -->
    <template
      v-if="
        item.arise_id &&
        (index !== ariseRow.index || item.order !== ariseRow.id)
      "
    >
      <template
        v-if="
          header.value === 'order' &&
          index !== passRow.index &&
          index !== newAriseRow.index &&
          index !== newPassRow.index
        "
      >
        <span :class="item[header.value] === '미선택' ? 'red--text' : ''">
          {{ item[header.value] }}
        </span>
      </template>
      <template
        v-if="
          header.value === 'liaison' &&
          index !== passRow.index &&
          index !== newPassRow.index
        "
      >
        <span :class="item[header.value] === '미선택' ? 'red--text' : ''">
          {{ item[header.value] }}
        </span>
      </template>
      <div class="bodyForm">
        <template v-if="header.value === 'arise_checkbox'">
          <div
            v-if="
              item.arise_status === '40' ||
              item.arise_status === '00' ||
              item.arise_status === '30'
            "
            class="d-flex justify-center align-center"
          >
            <input
              class="v-checkbox"
              type="checkbox"
              :checked="item.arise_checked"
              @click="checkItem(item, index, 'arise')"
            />
          </div>
        </template>
        <template v-else-if="header.value === 'arise_month'">
          {{ item[header.value] }}
        </template>
        <template v-else-if="header.value === 'arise_type'">
          <span :class="item[header.value] === '발행 예정' ? 'red--text' : ''">
            {{ item[header.value] }}
          </span>
        </template>
        <template v-else-if="header.value === 'arise_client'">
          {{ item[header.value] }}
        </template>

        <template v-else-if="header.value === 'arise_price'">
          <template v-if="!item.arise_eschamge">
            <div class="mainPriceloca">
              <div class="d-flex justify-center align-center">
                <div
                  class="vatBox"
                  :class="
                    item.arise_vat === true
                      ? 'greenStyle'
                      : item.arise_vat === false
                      ? 'redStyle'
                      : 'greenStyle'
                  "
                >
                  VAT
                </div>
                <div>
                  {{ item.arise_currency_sign }}
                </div>
              </div>
              <div
                class="claimPrice"
                :class="item.arise_price < 0 ? 'minusPrice' : ''"
              >
                {{
                  item.arise_price && item.arise_price !== "(비어있음)"
                    ? `${usingComma(item.arise_price)} `
                    : ""
                }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-left">
              <div class="d-flex pl-1">
                <div class="align-left">
                  <div class="d-flex justify-center align-center">
                    <div
                      class="vatBox"
                      :class="
                        item.arise_vat === true
                          ? 'greenStyle'
                          : item.arise_vat === false
                          ? 'redStyle'
                          : 'greenStyle'
                      "
                    >
                      VAT
                    </div>
                    <div>
                      {{ item.arise_eschamgePrice ? "₩" : "" }}
                    </div>
                  </div>
                </div>
                <div class="text-end" style="width: 100%">
                  {{
                    item.arise_eschamgePrice
                      ? item.arise_eschamgePrice
                      : "" | makeComma
                  }}
                </div>
              </div>
              <div
                style="font-size: 0.65rem; flex-grow: 1"
                class="unitGrid text-right"
              >
                <div class="text-left pl-1">
                  {{
                    item.arise_price
                      ? (item.arise_currency_sign
                          ? item.arise_currency_sign
                          : "₩") + usingComma(item.arise_price)
                      : ""
                  }}
                </div>
                <div class="text-right">|</div>
                <div class="">
                  {{
                    item.arise_eschamge
                      ? `₩${item.arise_eschamge}`
                      : "" | makeComma
                  }}
                </div>
              </div>
            </div>
          </template>
        </template>

        <template v-else-if="header.value === 'arise_passtype'">
          <div v-if="item.arise_passtype">
            <v-icon
              :color="
                item.arise_passtype === 1
                  ? 'orange'
                  : item.arise_passtype === 2
                  ? 'red'
                  : item.arise_passtype === 3
                  ? 'green'
                  : ''
              "
              >mdi-circle-slice-8</v-icon
            >
          </div>
        </template>
        <template v-else-if="header.value === 'arise_detail'">
          <div class="claimMemoRow">
            <div class="claimMemo">
              <ClaimMemo :Tooltip="item.arise_detail" />
            </div>
          </div>
        </template>

        <template v-else-if="header.value === 'arise_status'">
          {{ statusParse(item.arise_status) }}
        </template>
      </div>
    </template>

    <!-- 전가 -->
    <template
      v-if="
        item.pass_id && (index !== passRow.index || item.order !== passRow.id)
      "
    >
      <div class="bodyForm">
        <template
          v-if="
            header.value === 'order' &&
            index !== newAriseRow.index &&
            !item.arise_id
          "
        >
          <span :class="item[header.value] === '미선택' ? 'red--text' : ''">
            {{ item[header.value] }}
          </span>
        </template>
        <template
          v-if="
            header.value === 'liaison' &&
            index !== newAriseRow.index &&
            !item.arise_id
          "
        >
          <span :class="item[header.value] === '미선택' ? 'red--text' : ''">
            {{ item[header.value] }}
          </span>
        </template>
        <template v-if="header.value === 'pass_checkbox'">
          <div
            v-if="
              item.pass_status === '40' ||
              item.pass_status === '00' ||
              item.pass_status === '30'
            "
            class="d-flex justify-center align-center"
          >
            <input
              class="v-checkbox"
              type="checkbox"
              :checked="item.pass_checked"
              @click="checkItem(item, index, 'pass')"
            />
          </div>
        </template>
        <template v-else-if="header.value === 'pass_month'">
          {{ item[header.value] }}
        </template>
        <template v-else-if="header.value === 'pass_type'">
          <span :class="item[header.value] === '발행 예정' ? 'red--text' : ''">
            {{ item[header.value] }}
          </span>
        </template>
        <template v-else-if="header.value === 'pass_client'">
          {{ item[header.value] }}
        </template>
        <template v-else-if="header.value === 'pass_price'">
          <template v-if="!item.pass_eschamge">
            <div class="mainPriceloca">
              <div class="d-flex justify-center align-center">
                <div
                  class="vatBox"
                  :class="
                    item.pass_vat === true
                      ? 'greenStyle'
                      : item.pass_vat === false
                      ? 'redStyle'
                      : 'greenStyle'
                  "
                >
                  VAT
                </div>
                <div class="">
                  {{ item.pass_currency_sign }}
                </div>
              </div>
              <div
                class="claimPrice"
                :class="item.pass_price < 0 ? 'minusPrice' : ''"
              >
                {{
                  item.pass_price && item.pass_price !== "(비어있음)"
                    ? `${usingComma(item.pass_price)} `
                    : ""
                }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-left">
              <div class="d-flex pl-1">
                <div class="align-left">
                  <div class="d-flex justify-center align-center">
                    <div
                      class="vatBox"
                      :class="
                        item.arise_vat === true
                          ? 'greenStyle'
                          : item.arise_vat === false
                          ? 'redStyle'
                          : 'greenStyle'
                      "
                    >
                      VAT
                    </div>
                    <div>
                      {{ item.pass_eschamgePrice ? "₩" : "" }}
                    </div>
                  </div>
                </div>
                <div class="text-end" style="width: 100%">
                  {{
                    item.pass_eschamgePrice
                      ? item.pass_eschamgePrice
                      : "" | makeComma
                  }}
                </div>
              </div>
              <div
                style="font-size: 0.65rem; flex-grow: 1"
                class="unitGrid text-right"
              >
                <div class="text-left pl-1">
                  {{
                    item.pass_price
                      ? (item.pass_currency_sign
                          ? item.pass_currency_sign
                          : "₩") + usingComma(item.pass_price)
                      : ""
                  }}
                </div>
                <div class="text-right">|</div>
                <div class="">
                  {{
                    item.pass_eschamge
                      ? `₩${item.pass_eschamge}`
                      : "" | makeComma
                  }}
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else-if="header.value === 'pass_detail'">
          <div class="claimMemoRow">
            <div class="claimMemo">
              <ClaimMemo :Tooltip="item.pass_detail" />
            </div>
          </div>
        </template>
        <template v-else-if="header.value === 'pass_status'">
          {{ statusParse(item.pass_status) }}
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import OrderStatus from "@/assets/data/status/OrderStatus";
import ClaimMemo from "@/components/atoms/VTooltip/ClaimMemo";
export default {
  components: {
    ClaimMemo,
  },
  computed: {
    ...mapGetters({
      pageList: "getClaimList",

      ariseAllChecked: "getAriseAllChecked",
      passAllChecked: "getPassAllChecked",
    }),
  },
  props: [
    "header",
    "index",
    "item",
    "ariseRow",
    "passRow",
    "newAriseRow",
    "newPassRow",
  ],
  methods: {
    checkItem(item, i, type) {
      let isAllCheck = "";
      if (type === "arise") {
        item.arise_checked = !item.arise_checked;
        item.arise_index = i;
        if (item.arise_checked) item.saveTarget = "arise";

        isAllCheck =
          this.pageList.filter((x) => x.arise_checked).length ===
          this.pageList.filter((x) => x.arise_id).length;
        this.$emit("checkBoxCommit", "arise", isAllCheck);
      } else {
        item.pass_checked = !item.pass_checked;
        item.pass_index = i;
        if (item.pass_checked) item.saveTarget = "pass";

        isAllCheck =
          this.pageList.filter((x) => x.pass_checked).length ===
          this.pageList.filter((x) => x.pass_id).length;
        this.$emit("checkBoxCommit", "pass", isAllCheck);
      }
      this.$emit("setCheckBoxLength");
    },
    statusParse(item) {
      return OrderStatus[item];
    },
  },
};
</script>
