var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.item.arise_id &&
      (_vm.index !== _vm.ariseRow.index || _vm.item.order !== _vm.ariseRow.id)
    )?[(
        _vm.header.value === 'order' &&
        _vm.index !== _vm.passRow.index &&
        _vm.index !== _vm.newAriseRow.index &&
        _vm.index !== _vm.newPassRow.index
      )?[_c('span',{class:_vm.item[_vm.header.value] === '미선택' ? 'red--text' : ''},[_vm._v(" "+_vm._s(_vm.item[_vm.header.value])+" ")])]:_vm._e(),(
        _vm.header.value === 'liaison' &&
        _vm.index !== _vm.passRow.index &&
        _vm.index !== _vm.newPassRow.index
      )?[_c('span',{class:_vm.item[_vm.header.value] === '미선택' ? 'red--text' : ''},[_vm._v(" "+_vm._s(_vm.item[_vm.header.value])+" ")])]:_vm._e(),_c('div',{staticClass:"bodyForm"},[(_vm.header.value === 'arise_checkbox')?[(
            _vm.item.arise_status === '40' ||
            _vm.item.arise_status === '00' ||
            _vm.item.arise_status === '30'
          )?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('input',{staticClass:"v-checkbox",attrs:{"type":"checkbox"},domProps:{"checked":_vm.item.arise_checked},on:{"click":function($event){return _vm.checkItem(_vm.item, _vm.index, 'arise')}}})]):_vm._e()]:(_vm.header.value === 'arise_month')?[_vm._v(" "+_vm._s(_vm.item[_vm.header.value])+" ")]:(_vm.header.value === 'arise_type')?[_c('span',{class:_vm.item[_vm.header.value] === '발행 예정' ? 'red--text' : ''},[_vm._v(" "+_vm._s(_vm.item[_vm.header.value])+" ")])]:(_vm.header.value === 'arise_client')?[_vm._v(" "+_vm._s(_vm.item[_vm.header.value])+" ")]:(_vm.header.value === 'arise_price')?[(!_vm.item.arise_eschamge)?[_c('div',{staticClass:"mainPriceloca"},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"vatBox",class:_vm.item.arise_vat === true
                    ? 'greenStyle'
                    : _vm.item.arise_vat === false
                    ? 'redStyle'
                    : 'greenStyle'},[_vm._v(" VAT ")]),_c('div',[_vm._v(" "+_vm._s(_vm.item.arise_currency_sign)+" ")])]),_c('div',{staticClass:"claimPrice",class:_vm.item.arise_price < 0 ? 'minusPrice' : ''},[_vm._v(" "+_vm._s(_vm.item.arise_price && _vm.item.arise_price !== "(비어있음)" ? ((_vm.usingComma(_vm.item.arise_price)) + " ") : "")+" ")])])]:[_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"d-flex pl-1"},[_c('div',{staticClass:"align-left"},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"vatBox",class:_vm.item.arise_vat === true
                        ? 'greenStyle'
                        : _vm.item.arise_vat === false
                        ? 'redStyle'
                        : 'greenStyle'},[_vm._v(" VAT ")]),_c('div',[_vm._v(" "+_vm._s(_vm.item.arise_eschamgePrice ? "₩" : "")+" ")])])]),_c('div',{staticClass:"text-end",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.item.arise_eschamgePrice ? _vm.item.arise_eschamgePrice : ""))+" ")])]),_c('div',{staticClass:"unitGrid text-right",staticStyle:{"font-size":"0.65rem","flex-grow":"1"}},[_c('div',{staticClass:"text-left pl-1"},[_vm._v(" "+_vm._s(_vm.item.arise_price ? (_vm.item.arise_currency_sign ? _vm.item.arise_currency_sign : "₩") + _vm.usingComma(_vm.item.arise_price) : "")+" ")]),_c('div',{staticClass:"text-right"},[_vm._v("|")]),_c('div',{},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.item.arise_eschamge ? ("₩" + (_vm.item.arise_eschamge)) : ""))+" ")])])])]]:(_vm.header.value === 'arise_passtype')?[(_vm.item.arise_passtype)?_c('div',[_c('v-icon',{attrs:{"color":_vm.item.arise_passtype === 1
                ? 'orange'
                : _vm.item.arise_passtype === 2
                ? 'red'
                : _vm.item.arise_passtype === 3
                ? 'green'
                : ''}},[_vm._v("mdi-circle-slice-8")])],1):_vm._e()]:(_vm.header.value === 'arise_detail')?[_c('div',{staticClass:"claimMemoRow"},[_c('div',{staticClass:"claimMemo"},[_c('ClaimMemo',{attrs:{"Tooltip":_vm.item.arise_detail}})],1)])]:(_vm.header.value === 'arise_status')?[_vm._v(" "+_vm._s(_vm.statusParse(_vm.item.arise_status))+" ")]:_vm._e()],2)]:_vm._e(),(
      _vm.item.pass_id && (_vm.index !== _vm.passRow.index || _vm.item.order !== _vm.passRow.id)
    )?[_c('div',{staticClass:"bodyForm"},[(
          _vm.header.value === 'order' &&
          _vm.index !== _vm.newAriseRow.index &&
          !_vm.item.arise_id
        )?[_c('span',{class:_vm.item[_vm.header.value] === '미선택' ? 'red--text' : ''},[_vm._v(" "+_vm._s(_vm.item[_vm.header.value])+" ")])]:_vm._e(),(
          _vm.header.value === 'liaison' &&
          _vm.index !== _vm.newAriseRow.index &&
          !_vm.item.arise_id
        )?[_c('span',{class:_vm.item[_vm.header.value] === '미선택' ? 'red--text' : ''},[_vm._v(" "+_vm._s(_vm.item[_vm.header.value])+" ")])]:_vm._e(),(_vm.header.value === 'pass_checkbox')?[(
            _vm.item.pass_status === '40' ||
            _vm.item.pass_status === '00' ||
            _vm.item.pass_status === '30'
          )?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('input',{staticClass:"v-checkbox",attrs:{"type":"checkbox"},domProps:{"checked":_vm.item.pass_checked},on:{"click":function($event){return _vm.checkItem(_vm.item, _vm.index, 'pass')}}})]):_vm._e()]:(_vm.header.value === 'pass_month')?[_vm._v(" "+_vm._s(_vm.item[_vm.header.value])+" ")]:(_vm.header.value === 'pass_type')?[_c('span',{class:_vm.item[_vm.header.value] === '발행 예정' ? 'red--text' : ''},[_vm._v(" "+_vm._s(_vm.item[_vm.header.value])+" ")])]:(_vm.header.value === 'pass_client')?[_vm._v(" "+_vm._s(_vm.item[_vm.header.value])+" ")]:(_vm.header.value === 'pass_price')?[(!_vm.item.pass_eschamge)?[_c('div',{staticClass:"mainPriceloca"},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"vatBox",class:_vm.item.pass_vat === true
                    ? 'greenStyle'
                    : _vm.item.pass_vat === false
                    ? 'redStyle'
                    : 'greenStyle'},[_vm._v(" VAT ")]),_c('div',{},[_vm._v(" "+_vm._s(_vm.item.pass_currency_sign)+" ")])]),_c('div',{staticClass:"claimPrice",class:_vm.item.pass_price < 0 ? 'minusPrice' : ''},[_vm._v(" "+_vm._s(_vm.item.pass_price && _vm.item.pass_price !== "(비어있음)" ? ((_vm.usingComma(_vm.item.pass_price)) + " ") : "")+" ")])])]:[_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"d-flex pl-1"},[_c('div',{staticClass:"align-left"},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"vatBox",class:_vm.item.arise_vat === true
                        ? 'greenStyle'
                        : _vm.item.arise_vat === false
                        ? 'redStyle'
                        : 'greenStyle'},[_vm._v(" VAT ")]),_c('div',[_vm._v(" "+_vm._s(_vm.item.pass_eschamgePrice ? "₩" : "")+" ")])])]),_c('div',{staticClass:"text-end",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.item.pass_eschamgePrice ? _vm.item.pass_eschamgePrice : ""))+" ")])]),_c('div',{staticClass:"unitGrid text-right",staticStyle:{"font-size":"0.65rem","flex-grow":"1"}},[_c('div',{staticClass:"text-left pl-1"},[_vm._v(" "+_vm._s(_vm.item.pass_price ? (_vm.item.pass_currency_sign ? _vm.item.pass_currency_sign : "₩") + _vm.usingComma(_vm.item.pass_price) : "")+" ")]),_c('div',{staticClass:"text-right"},[_vm._v("|")]),_c('div',{},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.item.pass_eschamge ? ("₩" + (_vm.item.pass_eschamge)) : ""))+" ")])])])]]:(_vm.header.value === 'pass_detail')?[_c('div',{staticClass:"claimMemoRow"},[_c('div',{staticClass:"claimMemo"},[_c('ClaimMemo',{attrs:{"Tooltip":_vm.item.pass_detail}})],1)])]:(_vm.header.value === 'pass_status')?[_vm._v(" "+_vm._s(_vm.statusParse(_vm.item.pass_status))+" ")]:_vm._e()],2)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }