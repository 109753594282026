<template>
  <div class="d-flex flex-column">
    <v-date-picker
      :value="value"
      no-title
      @input="$emit('input', $event)"
      type="month"
      locale="kr"
      width="100%"
    ></v-date-picker>
  </div>
</template>
<script>
export default {
  props: ["value", "input"],
};
</script>
